import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from '../context/User/userContext';

const AdminRoute = ({ children }) => {

    const { user } = useContext(UserContext)

    if (user && (user?.role === 'admin' || user?.role === 'super_admin')) return children;
    return <Navigate to="/" />;
};

export default AdminRoute;