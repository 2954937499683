/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import classes from './tickets.module.css';
import Skeleton from '../../../components/skeleton/skeleton';
import { FaBan } from "react-icons/fa";

const url = process.env.REACT_APP_BACKEND_URL;

export default function Users() {
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [otherData, setOtherData] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('all');


    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const fetchUsers = async (page = 1) => {

        const config = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('tok')}`
            }
        }

        setIsLoading(true);

        try {
            const response = await axios.get(`${url}/users/all?searchQuery=${searchQuery}&page=${page}&vip=${activeTab !== 'all' ? 'true' : ''}`, config);
            setUsers(response.data.users);
            const { users, ...otherData } = response.data;
            setOtherData(otherData);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const { totalCount, hasNextPage, hasPreviousPage, page } = otherData;

    useEffect(() => {
        fetchUsers(1);
    }, [searchQuery, activeTab]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleDeleteUser = async (id) => {
        const config = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('tok')}`
            }
        }
        try {
            await axios.delete(`${url}/users/${id}`, config);
            fetchUsers(page);
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                <div className={classes.tabs}>
                    <button onClick={() => handleTabChange('all')} className={activeTab === 'all' ? classes.active : ''}>All</button>
                    <button onClick={() => handleTabChange('vip')} className={activeTab === 'vip' ? classes.active : ''}>VIP</button>
                </div>
                <div className={classes.inputContainer}>
                    <input
                        type="text"
                        placeholder="Search by username or email..."
                        value={searchQuery}
                        onChange={handleSearch}
                        className={classes.input}
                    />
                </div>

                <p className={classes.count}>Count: {totalCount}</p>

                {isLoading ?
                    <div className={classes.loaderContainer}>
                        <Skeleton width={'100%'} height={'100%'} />
                    </div>
                    :
                    <>
                        <table className={classes.table}>
                            <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Ban</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, index) => (
                                    <tr key={user._id}>
                                        <td>{index + 1}</td>
                                        <td>{`${user.first_name} ${user.last_name}`}</td>
                                        <td>{user.email}</td>
                                        <td>
                                            <button className={classes.buttonContainer} onClick={() => handleDeleteUser(user._id)}>
                                                <FaBan className={classes.deleteBtn} />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className={classes.pagination}>
                            <button onClick={() => fetchUsers(parseInt(page) - 1)} disabled={!hasPreviousPage}>Previous</button>
                            <span>Page {page} </span>
                            <button onClick={() => fetchUsers(parseInt(page) + 1)} disabled={!hasNextPage}>Next</button>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}
