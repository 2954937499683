import { FiHome } from 'react-icons/fi'
import classes from './navigationContainer.module.css'
import { PiCrownSimpleBold } from 'react-icons/pi'
import { SiConvertio } from 'react-icons/si'
import { useNavigate } from 'react-router-dom'
import { vipRoute } from '../../constant/routes'

export function NavigationContainer() {

    const navItems = [
        { title: 'Home', icon: <FiHome className={classes.icon} />, route: '/' },
        { title: 'VIP', icon: <PiCrownSimpleBold className={classes.icon} />, route: vipRoute },
        { title: 'Convert', icon: <SiConvertio className={classes.icon} />, route: '/convert' },
    ]

    const navigate = useNavigate()
    const path = window.location.pathname

    return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                {
                    navItems?.map((item, index) => (
                        <div onClick={() => navigate(item.route)} className={classes.navContainer} key={index}>
                            {item?.icon}
                            {
                                item.route === path ?

                                    <div className={classes.indicator}/>
                                    :
                                    <p >{item.title}</p>

                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}