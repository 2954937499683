import { format } from 'date-fns';


export const formatDateHeader =(dateStr) =>{
    
    const today = format(new Date(), 'yyyy-MM-dd');
    

    const yesterday = format(new Date(Date.now() - 864e5), 'yyyy-MM-dd');

    if (dateStr === today) {
        return 'Today';
    } else if (dateStr === yesterday) {
        return 'Yesterday';
    } else {
        return format(new Date(dateStr), 'dd/MM/yyyy');
    }
}