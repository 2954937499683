import React, { useState } from 'react';
import axios from 'axios';
import classes from './game.module.css';
import { show } from '../../../constant/toastOptions';
import { Loader } from '../../../components/loader/loader';

const url = process.env.REACT_APP_BACKEND_URL;

export default function SendPushNotification() {
    const defaultValue = {
        title: '',
        body: ''
    };
    const [values, setValues] = useState(defaultValue);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleSendNotification = async (e) => {
        e.preventDefault();

        const { title, body } = values;

        if (!title || !body) {
            show('Both title and body are required!', 'error');
            return;
        }

        const notificationData = {
            title,
            body,
        };

        const config = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('tok')}`,
                'content-type': 'application/json',
            },
        };

        setIsLoading(true);
        try {
            await axios.post(`${url}/push_notification/send/notification`, JSON.stringify(notificationData), config);
            show('Notification sent successfully!', 'success');
            setValues(defaultValue);
        } catch (error) {
            const errorMessage = error?.response?.data?.msg || 'Something went wrong';
            show(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                <p className={classes.head}>Send Push Notification</p>

                <form onSubmit={handleSendNotification}>
                    <div className={classes.inputContainer}>
                        <input
                            placeholder="Enter Notification Title"
                            type="text"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            className={classes.input}
                        />
                    </div>

                    <div className={classes.inputContainer}>
                        <input
                            placeholder="Enter Notification Body"
                            name="body"
                            type="text"
                            value={values.body}
                            onChange={handleChange}
                            className={classes.input}
                        />
                    </div>

                    <button type="submit" className={classes.button} disabled={isLoading}>
                        {isLoading ? <Loader /> : 'Send Notification'}
                    </button>
                </form>
            </div>
        </div>
    );
}
