import React, { useState } from 'react';
import axios from 'axios';
import classes from './changePassword.module.css';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { show } from '../../constant/toastOptions';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Loader } from '../../components/loader/loader';
import { profilePath } from '../../constant/routes';
import { useNavigate } from 'react-router-dom';

const url = process.env.REACT_APP_BACKEND_URL;

export default function ChangePassword() {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();

        if (!oldPassword || !newPassword || !confirmNewPassword) {
            show('All fields are required!', 'error');
            return;
        }

        if (newPassword !== confirmNewPassword) {
            show('New passwords do not match!', 'error');
            return;
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('tok')}`,
            },
        };

        setIsLoading(true);
        try {
            await axios.patch(`${url}/account/password/change`, JSON.stringify({ old_password: oldPassword, new_password: newPassword }), config);
            show('Password changed successfully!', 'success');
            navigate (profilePath)
        } catch (error) {
            const errorMessage = error?.response?.data?.msg || 'Something went wrong';
            show(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={classes.container}>
            <form onSubmit={handleChangePassword} className={classes.subContainer}>
                <p className={classes.title}>Change Password</p>
                <div className={classes.iconInputGrouped}>
                    <input
                        style={{ border: 'none', margin: 0 }}
                        type={isPasswordVisible ? 'text' : 'password'}
                        placeholder="Enter your old password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        className={classes.input}
                    />
                    <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className={classes.eyeIcon}
                    >
                        {isPasswordVisible ? <FiEyeOff /> : <FiEye />}
                    </button>
                </div>


                <input
                    type={isPasswordVisible ? 'text' : 'password'}
                    placeholder="Enter your new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className={classes.input}
                />
                <input
                    type={isPasswordVisible ? 'text' : 'password'}
                    placeholder="Confirm your new password"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    className={classes.input}
                />

                <button type="submit" className={classes.button} disabled={isLoading}>
                    {isLoading ? <Loader /> : 'Change Password'}
                </button>
            </form>
            <ToastContainer />
        </div>
    );
}
