import React, { useState } from 'react';
import axios from 'axios';
import classes from './game.module.css';
import { show } from '../../../constant/toastOptions';
import { Loader } from '../../../components/loader/loader';

const url = process.env.REACT_APP_BACKEND_URL;

export default function UploadBanner() {
    const [image, setImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState('Choose Banner Image');

    const handleChange = (e) => {
        const { files } = e.target;
        setImage(files ? files[0] : null);

        if (files && files[0]) {
            setFileName(files[0].name);
        }
    };

    const handleUploadBanner = async (e) => {
        e.preventDefault();

        if (!image) {
            show('Please select a banner image!', 'error');
            return;
        }

        const formData = new FormData();
        formData.append('image', image);
        formData.append('name', 'bannerOne');

        const config = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('tok')}`,
                'content-type': 'multipart/form-data',
            },
        };

        setIsLoading(true);
        try {
            await axios.patch(`${url}/banner`, formData, config);
            show('Banner uploaded successfully!', 'success');
            setImage(null);
            setFileName('Choose Banner Image');
        } catch (error) {
            const errorMessage = error?.response?.data?.msg || 'Something went wrong';
            show(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                <p className={classes.head}>Upload Banner</p>

                <form onSubmit={handleUploadBanner}>
                    <div className={classes.inputContainer}>
                        <input
                            type="file"
                            id="file"
                            name="image"
                            accept="image/*"
                            onChange={handleChange}
                            className={classes.fileInput}
                        />
                        <label htmlFor="file" className={classes.fileLabel}>
                            {fileName}
                        </label>
                    </div>

                    <button type="submit" className={classes.button} disabled={isLoading}>
                        {isLoading ? <Loader /> : 'Upload Banner'}
                    </button>
                </form>
            </div>
        </div>
    );
}
