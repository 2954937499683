import { useState } from "react";
import UserContext from "./userContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { show } from "../../constant/toastOptions";
import { loginPath, subscribePath, vipRoute } from "../../constant/routes";

const url = process.env.REACT_APP_BACKEND_URL;

const UserState = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [convertValues, setConvertValues] = useState({
    from: "Convert from",
    to: "Convert to",
    booking_code: "",
  });
  const [isShareModalVisible, setShareModalVisible] = useState('');
  const [gettingUser, setGettingUser] = useState(false)


  const navigate = useNavigate()


  const getUser = async (noNavigate) => {
    const token = localStorage.getItem("tok");



    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    setGettingUser(true)

    try {
      const res = await axios.get(
        `${url}/users/`,
        config
      );


      const data = res.data;

      setUser(data.updatedUser);
    } catch (error) {
      const msg = error?.response?.data?.msg || 'something went wrong'
      console.log(error?.response?.status === 401 && !noNavigate)
      if (error?.response?.status === 401 && !noNavigate) {
        localStorage.removeItem("tok");;
        return;
      }

      show(msg, 'error')
    } finally {
      setGettingUser(false)
    }
  };

  const changePassword = async (val) => {
    setLoading(true);
    const token = localStorage.getItem("tok");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const body = JSON.stringify(val);

    try {
      const res = await axios.patch(
        `${url}/account/password/change`,
        body,
        config
      );
      const data = res.data;
      alert(data.msg);
      setUser(data.updatedUser);
      navigate("/");
    } catch (error) {
      if (error.response?.data?.msg) {
        alert(error?.response?.data?.msg);
      } else {
        alert(`${error.message}!`);
      }
    } finally {
      setLoading(false);
    }
  };


  const toggleShareModal = (message) => {
    setShareModalVisible(message)
  }




  const [hasOverlay, setHasOverlay] = useState(true)
  const [gettingTickets, setGettingTickets] = useState(false);
  const [tickets, setTickets] = useState({
    tickets: [], hasNextPage: false, hasPreviousPage: false
  });


  const getVipTickets = async (page = 1) => {
    const token = localStorage.getItem('tok');

    if (!token) {
      navigate(`${loginPath}?redirect=${vipRoute}`)
      return;
    }

    setGettingTickets(true)

    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    };

    try {
      const res = await axios.get(`${url}/tickets/vip?page=${page}`, config);
      const data = res.data;

      // Check if new data is different from the current data
      if (JSON.stringify(data) !== JSON.stringify(tickets)) {
        setTickets(data);
      }

      setHasOverlay(false)

    } catch (error) {
      navigate(`${subscribePath}?redirect=${vipRoute}`)
    } finally {
      setGettingTickets(false)

    }
  }



  return (
    <UserContext.Provider
      value={{
        blurhash:
          "|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[",
        convertValues,
        setConvertValues,
        loading,
        user,
        getUser,
        changePassword,
        isShareModalVisible,
        toggleShareModal,
        setLoading,
        gettingUser,
        setUser,
        setShareModalVisible,
        hasOverlay,
        gettingTickets,
        getVipTickets,
        tickets
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserState;
