import React from 'react'
import { FaLongArrowAltLeft } from "react-icons/fa";
import classes from './headerTwo.module.css'
import { useNavigate } from 'react-router-dom';

export default function HeaderTwo({ color, title }) {
    const navigate = useNavigate()

    const goBack = () => {
        navigate(-1)
    }

    return (
        <div style={{ background: color }} className={classes.container}>

            <div className={classes.subContainer}>
                <div onClick={goBack} className={classes.arrowContainer}>
                    <FaLongArrowAltLeft />
                </div>

                {title}
            </div>

        </div>
    )
}
