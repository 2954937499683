import React, { useState } from 'react';
import axios from 'axios';
import classes from './game.module.css';
import { show } from '../../../constant/toastOptions';
import { Loader } from '../../../components/loader/loader';

const url = process.env.REACT_APP_BACKEND_URL;

export default function AddTipster() {
    const defaultValue = {
        name: '',
        image: null,
    };
    const [values, setValues] = useState(defaultValue);
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState('Choose Image');

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setValues({
            ...values,
            [name]: files ? files[0] : value,
        });

        if (files && files[0]) {
            setFileName(files[0].name);
        }
    };

    const handleAddTipster = async (e) => {
        e.preventDefault();

        const { name, image } = values;

        if (!name || !image) {
            show('All fields are required!', 'error');
            return;
        }

        const formData = new FormData();
        formData.append('punter_name', name);
        formData.append('punter_image', image);

        const config = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('tok')}`,
                'content-type': 'multipart/form-data',
            },
        };

        setIsLoading(true);
        try {
            await axios.post(`${url}/punters/create`, formData, config);
            show('Tipster added successfully!', 'success');
            setValues(defaultValue);
            setFileName('Choose Image');
        } catch (error) {
            const errorMessage = error?.response?.data?.msg || 'Something went wrong';
            show(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                <p className={classes.head}>Add Tipster</p>

                <form onSubmit={handleAddTipster}>
                    <div className={classes.inputContainer}>
                        <input
                            placeholder="Enter Tipster's Name"
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            className={classes.input}
                        />
                    </div>

                    <div className={classes.inputContainer}>
                        <input
                            type="file"
                            id="file"
                            name="image"
                            accept="image/*"
                            onChange={handleChange}
                            className={classes.fileInput}
                        />
                        <label htmlFor="file" className={classes.fileLabel}>
                            {fileName}
                        </label>
                    </div>

                    <button type="submit" className={classes.button} disabled={isLoading}>
                        {isLoading ? <Loader /> : 'Add Tipster'}
                    </button>
                </form>
            </div>
        </div>
    );
}
