import React, { useState } from 'react';
import axios from 'axios';
import classes from './resetPassword.module.css';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { show } from '../../constant/toastOptions';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Loader } from '../../components/loader/loader';
import { profilePath } from '../../constant/routes';
import { useNavigate, useLocation } from 'react-router-dom';

const url = process.env.REACT_APP_BACKEND_URL;

export default function ResetPassword() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();

        const queryParams = new URLSearchParams(location.search);
        const confirmToken = queryParams.get('token');

        if (!newPassword || !confirmNewPassword) {
            show('All fields are required!', 'error');
            return;
        }

        if (newPassword !== confirmNewPassword) {
            show('New passwords do not match!', 'error');
            return;
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        setIsLoading(true);
        try {
            await axios.patch(`${url}/auth/resetPassword`,
                JSON.stringify({ password: newPassword, token: confirmToken }),
                config
            );
            show('Password reset successfully!', 'success');
            navigate('/');
        } catch (error) {
            const errorMessage = error?.response?.data?.msg || 'Something went wrong';
            show(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={classes.container}>
            <form onSubmit={handleChangePassword} className={classes.subContainer}>
                <p className={classes.title}>Reset Password</p>

                <div className={classes.iconInputGrouped}>
                    <input
                        style={{ border: 'none', margin: 0 }}
                        type={isPasswordVisible ? 'text' : 'password'}
                        placeholder="Enter your new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className={classes.input}
                    />

                    <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className={classes.eyeIcon}
                    >
                        {isPasswordVisible ? <FiEyeOff /> : <FiEye />}
                    </button>
                </div>


                <input
                    type={isPasswordVisible ? 'text' : 'password'}
                    placeholder="Confirm your new password"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    className={classes.input}
                />


                <button type="submit" className={classes.button} disabled={isLoading}>
                    {isLoading ? <Loader /> : 'Reset Password'}
                </button>
            </form>
            <ToastContainer />
        </div>
    );
}
