import React, { useContext } from 'react';
import classes from './shareModal.module.css';
import { IoLogoWhatsapp } from "react-icons/io5";
import { FaRegMessage } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { IoMailOpenOutline } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";
import { LiaTelegramPlane } from "react-icons/lia";
import UserContext from '../../context/User/userContext';
import { IoIosCloseCircle } from "react-icons/io";

export default function ShareModal() {

    const { isShareModalVisible, setShareModalVisible } = useContext(UserContext)

    const apps = [
        { name: 'Message', color: '#25D366', route: 'sms:', icon: <FaRegMessage className={classes.icon} /> },
        { name: 'Telegram', color: '#0088cc', route: 'https://t.me/share/url?url=&text=', icon: <LiaTelegramPlane className={classes.icon} /> },
        { name: 'Whatsapp', color: '#25D366', route: 'https://wa.me/?text=', icon: <IoLogoWhatsapp className={classes.icon} /> },
        { name: 'Twitter', color: '#1DA1F2', route: 'https://twitter.com/intent/tweet?text=', icon: <FaXTwitter className={classes.icon} /> },
        { name: 'Instagram', color: '#E1306C', route: 'https://www.instagram.com/', icon: <FaInstagram className={classes.icon} /> },
        { name: 'Mail address', color: '#BB001B', route: 'mailto:?subject=Check this out&body=', icon: <IoMailOpenOutline className={classes.icon} /> },
    ];

    const shareCode = (app) => {
        window.open(`${app.route}${encodeURIComponent(isShareModalVisible)}`, '_blank');
    };

    return (
        <div className={isShareModalVisible ? classes.container : classes.containerInactive}>
            <div className={isShareModalVisible ? classes.subContainer : classes.subContainerInactive}>

                <IoIosCloseCircle onClick={() => { setShareModalVisible('') }} className={classes.closeIcon} />
                <p className={classes.title}>Share Code</p>
                <div className={classes.appContainer}>
                    {apps.map((app, index) => (
                        <button
                            key={index}
                            className={classes.appButton}
                            onClick={() => shareCode(app)}
                        >
                            <span style={{ backgroundColor: app.color }} className={classes.iconContainer}>{app.icon}</span>
                            {app.name}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}
