/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import classes from './verifyAccount.module.css';
import { loginPath } from '../../constant/routes';

const url = process.env.REACT_APP_BACKEND_URL;

export function VerifyAccount() {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null); // "success", "error", or null
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const confirmId = searchParams.get('confirm'); // Get 'confirm' parameter from the route query

    useEffect(() => {
        if (confirmId) {
            verifyAccount();
        }
    }, [confirmId]);

    const verifyAccount = async () => {
        setLoading(true);
        setStatus(null);

        try {
            await axios.get(`${url}/auth/verify/${confirmId}`);
            setStatus('success');

        } catch (error) {
            console.log(error);
            setStatus('error');
        } finally {
            setLoading(false);

            // Navigate to the homepage after 3 seconds
            setTimeout(() => {
                navigate(loginPath);
            }, 3000);
        }
    };

    return (
        <div className={classes.verifyContainer}>
            {loading ? (
                <p>Loading...</p>
            ) : status === 'success' ? (
                <p className={classes.successMessage}>
                    Account confirmed successfully.  Redirecting you to login in 3seconds...
                </p>
            ) : status === 'error' ? (
                <p className={classes.errorMessage}>
                    Error verifying account. Redirecting you to login in 3seconds...
                </p>
            ) : (
                <p>Verifying your account, please wait...</p>
            )}
        </div>
    );
}
