import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from '../context/User/userContext';

const PrivateRoute = ({ children }) => {

    const { user } = useContext(UserContext)

    if (user) return children;
    return <Navigate to="/" />;
};

export default PrivateRoute;