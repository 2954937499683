import { toast } from "react-toastify";

export const show = (message, type='error') => {

    if (!message || !type) return;

    toast[type](message, {
        position: "top-center",
        hideProgressBar: true
    });
}