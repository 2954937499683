/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import classes from './index.module.css';
import Skeleton from '../../components/skeleton/skeleton';
import axios from 'axios';
import { IoCloseCircle } from "react-icons/io5";
import { FiCopy } from "react-icons/fi";
import { format } from 'date-fns';
import { formatDateHeader } from '../../helpers/formatDateHeader';
import { timeFormatter } from '../../helpers/timeFormatter';
import { show } from '../../constant/toastOptions';
import UserContext from '../../context/User/userContext';
import { bookieOptions, BOOKIES, bookiesDestination, directPlay } from '../../constant/bookies';
import { MdRefresh } from 'react-icons/md';
import noGame from '../../assets/images/nothing (1).png'
import { Loader } from '../../components/loader/loader';
import { useNavigate } from 'react-router-dom';
import { convertRoute } from '../../constant/routes';


const url = process.env.REACT_APP_BACKEND_URL;

const oddsFilter = [
    { minOdds: 0, maxOdds: 50 },
    { minOdds: 51, maxOdds: 100 },
    { minOdds: 101, maxOdds: 200 },
    { minOdds: 201, maxOdds: 500 },
    { minOdds: 501, maxOdds: 1000 },
    { minOdds: 1001, maxOdds: Infinity },
];

const defaultSelectedOdds = {
    minOdds: 0,
    maxOdds: Infinity
}

export default function Index() {
    const [punters, setPunters] = useState([]);
    const [tickets, setTickets] = useState({
        tickets: [], hasNextPage: false, hasPreviousPage: false, page: 1
    });

    const [refreshing, setRefreshing] = useState(false);
    const [gettingPunters, setGettingPunters] = useState(false);
    const [gettingTickets, setGettingTickets] = useState(false);
    const scrollableRef = useRef(null)
    const [selectedPunter, setSelectedPunters] = useState([])
    const [selectedOdds, setSelectedOdds] = useState(defaultSelectedOdds);

    const [banner, setBanner] = useState('');


    const getBanner = async () => {
        try {
            const res = await axios.get(`${url}/banner`);
            const data = res.data;
            setBanner(data?.banners[0]?.image);
        } catch (error) {
            console.log(error);
        }
    };

    const navigate = useNavigate()

    const filter = (selectedOdds.minOdds !== 0 || selectedOdds.maxOdds !== Infinity) || (selectedPunter.length > 0)
    const clearFilter = () => {
        setSelectedOdds(defaultSelectedOdds)
        setSelectedPunters([])
    }



    const getPunters = async () => {
        try {
            const res = await axios.get(`${url}/punters`);
            const data = res.data.punters;
            setPunters(data);
        } catch (error) {
            console.log(error);
        }
    };

    const getTickets = async () => {
        try {
            const res = await axios.get(`${url}/tickets?gameOwner=${selectedPunter}&minOdds=${selectedOdds.minOdds}&maxOdds=${selectedOdds.maxOdds}`);
            const data = res.data;
            setTickets(data);



        } catch (error) {
            console.log(error);
        }
    };


    useEffect(() => {
        const fn = async () => {
            setGettingTickets(true);
            await getTickets();
            setGettingTickets(false);
        };

        fn();

        const intervalId = setInterval(() => {
            getTickets();
        }, 5 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, [selectedOdds, selectedPunter]);

    const onRefresh = async () => {
        setRefreshing(true);
        await getPunters();
        await getTickets();
        setRefreshing(false);
    };

    useEffect(() => {
        const fn = async () => {
            setGettingPunters(true);
            await getPunters();
            setGettingPunters(false);
        };

        fn();
        getBanner()
    }, []);


    const onScroll = () => {
        const scrollable = scrollableRef.current;
        if (scrollable) {
            const { scrollTop, scrollHeight, clientHeight } = scrollable;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                // User has reached the bottom, trigger loading more
                if (tickets.hasNextPage) {
                    loadMoreTickets();
                }
            }
        }
    };

    const [loadingMoreTickets, setLoadingMoreTickets] = useState(false)
    const loadMoreTickets = async () => {
        // Logic to load more tickets
        setLoadingMoreTickets(true)
        try {
            const res = await axios.get(`${url}/tickets?gameOwner=${filter}&page=${tickets.page + 1}&minOdds=${selectedOdds.minOdds}&maxOdds=${selectedOdds.maxOdds}`);
            const newTickets = res.data;
            setTickets(prev => ({
                ...prev,
                tickets: [...prev.tickets, ...newTickets.tickets],
                hasNextPage: newTickets.hasNextPage
            }));
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingMoreTickets(false)
        }
    };

    useEffect(() => {
        const scrollable = scrollableRef.current;
        if (scrollable) {
            scrollable.addEventListener('scroll', onScroll);
        }
        return () => {
            if (scrollable) {
                scrollable.removeEventListener('scroll', onScroll);
            }
        };
    }, [tickets]);


    const togglePunterSelection = (punterName) => {
        if (selectedPunter.includes(punterName)) {
            setSelectedPunters(selectedPunter.filter(name => name !== punterName));
        } else {
            setSelectedPunters([...selectedPunter, punterName]);
        }
    };

    const toggleOddsSelection = (minOdds, maxOdds) => {
        setSelectedOdds({ minOdds, maxOdds })
    };


    const joinedPunters = selectedPunter.map(item => item).join(', ')


    const handleShare = (code, platform) => {
        if (navigator.share) {
            navigator
                .share({
                    title: `I just got this ${platform} - ${code} from BestCodes, you can visit the website to get quick and and sure odds`,
                    text: "https://www.bestcodes.vip/",
                })
                .then(() => {
                    show('Code shared!', 'success');
                })
                .catch((error) => {
                    show('Error sharing code!', 'error');
                });
        } else {
            alert("Sharing is not supported on this browser.");
        }
    };

    const convert = (code, bookie) => {
        const bookieValue = bookieOptions.find(item => item.bestCodeName === bookie);
        navigate(`${convertRoute}?code=${code}&bookie=${bookieValue.bookie}`)
    }



    return (
        <div className={classes.container}>

            <div className={classes.sticky}>
                <div className={classes.refreshContainer}>
                    <p className={classes.title} style={{ width: '100%' }}>Filter by top tipsters on our platform</p>
                    <p onClick={() => onRefresh()} className={classes.refresh}>refresh <MdRefresh
                        className={refreshing ? classes.rotating : classes.rotating_inactive} /></p>
                </div>

                {gettingPunters ? (
                    <div className={classes.groupedPunters}>
                        {[...Array(3)].map((_, index) => (
                            <div key={index} className={classes.skeletonContainer}>
                                <Skeleton width={'100%'} height={'100%'} />
                            </div>
                        ))}
                    </div>
                ) : (
                    <>
                        <div className={classes.groupedPunters}>
                            {punters?.map((item, index) => (
                                <div style={selectedPunter.includes(item.punter_name) ? { borderColor: '#de374b' } : {}} onClick={() => togglePunterSelection(item.punter_name)} className={classes.punterContainer} key={index}>
                                    <img src={item.punter_image} className={classes.punterImage} alt='' />
                                    <p className={classes.punterName}>{item.punter_name}</p>
                                </div>
                            ))}
                        </div>


                        <p className={classes.oddsTitle}>Filter by odds</p>
                        <div className={classes.groupedPunters}>

                            <div onClick={() => toggleOddsSelection(0, Infinity)} style={selectedOdds.minOdds === 0 && selectedOdds.maxOdds === Infinity ? { borderColor: '#de374b' } : {}} className={classes.punterContainer} >
                                <p className={classes.punterName}>All odds</p>
                            </div>

                            {oddsFilter?.map((item, index) => (
                                <div onClick={() => toggleOddsSelection(item.minOdds, item.maxOdds)} style={selectedOdds.minOdds === item.minOdds && selectedOdds.maxOdds === item.maxOdds ? { borderColor: '#de374b' } : {}} className={classes.punterContainer} key={index}>
                                    <p className={`${classes.punterName} ${classes.punter_odds}`}>{`${item.minOdds} - ${item.maxOdds}`}</p>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>

            <div ref={scrollableRef} className={classes.scrollable}>

                {
                    gettingTickets ?
                        <div className={classes.groupedSkeletonTickets}>
                            {[...Array(2)].map((_, index) => (
                                <div key={index} className={classes.ticketSkeleton}>
                                    <Skeleton width={'100%'} height={'100%'} />
                                </div>
                            ))}
                        </div>

                        :


                        <Tickets clearFilter={clearFilter} filter={filter} data={tickets} handleShare={handleShare} joinedPunters={joinedPunters} selectedOdds={selectedOdds} convert={convert} banner={banner} />
                }
            </div>
            {loadingMoreTickets &&
                <div className={classes.loaderParentContainer}>
                    <div className={classes.loaderCon}>
                        <Loader orange={true} />
                    </div>
                </div>
            }


        </div>
    );
}


function Tickets({ filter, data, handleShare, joinedPunters, selectedOdds, clearFilter, convert, banner }) {
    const { tickets } = data;
    const [dummyCardPositions, setDummyCardPositions] = useState([]);
    const [isModalOpen, setModalOpen] = useState(null)
    const { hasOverlay, getUser, gettingUser, user } = useContext(UserContext)

    const openModal = async (value) => {
        setModalOpen(value)
    }

    useEffect(() => {
        getUser()
    }, [])




    useEffect(() => {
        // Generate dummy card positions when tickets change
        if (tickets.length > 0) {
            const positions = generateDummyCardPositions(tickets.length);
            setDummyCardPositions(positions);
        }
    }, [tickets]);

    const generateDummyCardPositions = (ticketCount) => {
        const positions = [];
        let currentPosition = 0;

        // Generate positions at random intervals
        while (currentPosition < ticketCount) {
            currentPosition += Math.floor(Math.random() * 3) + 2; // Adjust randomness
            if (currentPosition < ticketCount) {
                positions.push(currentPosition);
            }
        }
        return positions;
    };

    const handleCopy = (code) => {
        navigator.clipboard.writeText(code)
            .then(() => show('Code copied to clipboard!', 'success'))
            .catch(err => console.error('Failed to copy code: ', err));
    };

    const placeBet = (bookie, code) => {
        const link = bookiesDestination(bookie, code);
        window.open(link, '_blank');
    };


    return (
        <div className={classes.ticketsContainer}>
            <div className={classes.groupedPrompt}>
                <p className={classes.title}>  Showing tickets from {joinedPunters && joinedPunters.trim() !== ',' ? joinedPunters : 'all tipsters'}
                    {selectedOdds.minOdds === 0 && selectedOdds.maxOdds === Infinity ? ' All' : ` ${selectedOdds.minOdds}-${selectedOdds.maxOdds}`} odds</p>

                {
                    filter && <p onClick={clearFilter} className={classes.clearFilter}>
                        Clear filter
                        <IoCloseCircle className={classes.clearFilterIcon} />
                    </p>
                }

            </div>

            <div className={classes.allTickets}>
                {tickets.length <= 0 ? (
                    <div className={classes.noGameContainer}>
                        <img className={classes.noGame} src={noGame} alt='No games' />
                        <p className={classes.noGameText}>There are currently no {filter || 'running'} tickets at the moment</p>
                    </div>
                ) : (
                    tickets.map((item, index) => (
                        <React.Fragment key={index}>
                            <div className={classes.ticketContainer}>
                                <div className={classes.topContainer}>
                                    <div className={classes.copyContainer} onClick={() => handleCopy(item.code)}>
                                        <p className={classes.smallText}>{item.platform}</p>
                                        <p className={classes.largeBoldText}>{item.code}</p>
                                        <p className={classes.redText}>Copy code <FiCopy /></p>
                                    </div>
                                    <div className={classes.groupedTotalOddsGameOwner}>
                                        <p className={classes.largeOdds}>{item.totalOdds}</p>
                                        <p className={classes.smallText}>{item.countOfGames} game(s) by {item.gameOwner}</p>
                                    </div>
                                </div>

                                <div className={classes.groupedGamesContainer}>
                                    {item?.games?.map((game, gameIndex) => (
                                        <div key={gameIndex} className={classes.gameContainer}>
                                            <div>
                                                <p className={classes.prediction}>{game.prediction}</p>
                                                <p className={classes.game}>{game.game}</p>
                                                <p className={classes.timeStamp}>
                                                    {formatDateHeader(format(new Date(game.start_date), 'yyyy-MM-dd'))} at {timeFormatter(new Date(game.start_date).getTime())}
                                                </p>
                                            </div>
                                            <p className={classes.gameOdd}>{game.odds}</p>
                                        </div>
                                    ))}
                                </div>

                                <div className={classes.seeAllContainer}>
                                    <p onClick={() => openModal(item?.games)} className={classes.viewAllBtn}>
                                        See all
                                    </p>
                                </div>

                                {
                                    isModalOpen &&
                                    <div className={classes.gamesModalContainer}>
                                        <div className={classes.gamesModalSubContainer}>
                                            {gettingUser ? (
                                                <Loader />
                                            ) : (
                                                !user?.subscriptionExpiration || new Date(user?.subscriptionExpiration) < new Date() ? (
                                                    <div className={classes.bannerContainer}>
                                                        <img className={classes.banner} src={banner} alt='ad' />
                                                    </div>
                                                ) : null
                                            )}

                                            <div className={classes.modalGameContainerGrouped}>
                                                {isModalOpen?.map((game, gameIndex) => (
                                                    <div key={gameIndex} className={classes.gameContainer}>
                                                        <div>
                                                            <p className={classes.prediction}>{game.prediction}</p>
                                                            <p className={classes.game}>{game.game}</p>
                                                            <p className={classes.timeStamp}>
                                                                {formatDateHeader(format(new Date(game.start_date), 'yyyy-MM-dd'))} at {timeFormatter(new Date(game.start_date).getTime())}
                                                            </p>
                                                        </div>
                                                        <p className={classes.gameOdd}>{game.odds}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            <p onClick={() => setModalOpen(null)} className={classes.closeModalBtn}>Close</p>

                                        </div>
                                    </div>
                                }



                                {directPlay.includes(item.platform) && (
                                    <button onClick={() => placeBet(item.platform, item.code)} className={classes.placeBetBtn}>
                                        Place Bet
                                    </button>
                                )}

                                <div className={classes.shareBtnContainer}>
                                    <button className={classes.shareBtn} onClick={() => handleShare(item.code, item.platform)}>Share Code</button>
                                    <button className={classes.shareBtn} onClick={() => convert(item.code, item.platform)}>Convert Code</button>
                                </div>
                            </div>

                            {/* Conditionally render DummyCard */}
                            {dummyCardPositions.includes(index) && hasOverlay && <DummyCard />}
                        </React.Fragment>
                    ))
                )}
            </div>




        </div>
    );
}

const DummyCard = () => {
    const { getVipTickets } = useContext(UserContext)
    const bookiesArray = Object.values(BOOKIES)

    return (
        <div onClick={getVipTickets} className={classes.ticketContainer}>
            <div className={classes.topContainer}>
                <div className={classes.copyContainer}>
                    <p className={classes.smallText}>{bookiesArray[Math.floor(Math.random() * 3) + 1]}</p>
                    <p className={classes.largeBoldText}>VIP CODE </p>
                    <p className={classes.redText}>Copy code <FiCopy /></p>
                </div>
                <div className={classes.groupedTotalOddsGameOwner}>
                    <p className={classes.largeOdds}> {(Math.random() * 1000 + 1).toFixed(2)}</p>
                    <p className={classes.smallText}>{Math.floor(Math.random() * 10)}  game(s)</p>
                </div>
            </div>



            <button className={classes.placeBetBtn}>
                Place Bet
            </button>


            <div className={classes.shareBtnContainer}>
                <button className={classes.shareBtn} >Share Code</button>
                <button className={classes.shareBtn}>Convert Code</button>
            </div>
        </div>
    )
}