import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import classes from './sendMail.module.css';
import { show } from '../../../constant/toastOptions';
import { Loader } from '../../../components/loader/loader';

const url = process.env.REACT_APP_BACKEND_URL;

export default function SendMail() {
    const defaultValue = {
        title: '',
        body: ''
    };
    const [values, setValues] = useState(defaultValue);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (name, value) => {
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleSendMail = async (e) => {
        e.preventDefault();
    
        const { title, body } = values;
    
        if (!title || !body) {
            show('All fields are required!', 'error');
            return;
        }
    
        const config = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('tok')}`,
                'Content-Type': 'application/json',
            },
        };
    
        setIsLoading(true);
        try {
            // Send JSON object rather than a JSON string
            await axios.post(`${url}/mail`, { subject: title, mail: body }, config);
            show('Mail sent successfully!', 'success');
            setValues(defaultValue);
        } catch (error) {
            const errorMessage = error?.response?.data?.msg || 'Something went wrong';
            show(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };
    

    return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                <p className={classes.head}>Send Mail</p>

                <form onSubmit={handleSendMail}>
                    <div className={classes.inputContainer}>
                        <input
                            placeholder="Enter Mail Title"
                            type="text"
                            name="title"
                            value={values.title}
                            onChange={(e) => handleChange('title', e.target.value)}
                            className={classes.input}
                        />
                    </div>

                    <div className={classes.inputContainer}>
                        <ReactQuill
                            value={values.body}
                            onChange={(value) => handleChange('body', value)}
                            className={classes.quill}
                        />
                    </div>

                    <button type="submit" className={classes.button} disabled={isLoading}>
                        {isLoading ? <Loader /> : 'Send Mail'}
                    </button>
                </form>
            </div>
        </div>
    );
}
