/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import classes from './tickets.module.css';
import Skeleton from '../../../components/skeleton/skeleton';
import { MdDeleteForever } from "react-icons/md";

const url = process.env.REACT_APP_BACKEND_URL;

export default function Tickets() {
    const [activeTab, setActiveTab] = useState('regular');
    const [tickets, setTickets] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [otherData, setOtherData] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const fetchTickets = async (page = 1) => {
        const config = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('tok')}`
            }
        };

        setIsLoading(true);

        try {
            const response = await axios.get(`${url}/tickets/${activeTab === 'vip' ? 'vip' : ""}?searchQuery=${searchQuery}&page=${page}`, config);
            setTickets(response.data.tickets);
            const { tickets, ...otherData } = response.data;
            setOtherData(otherData);
        } catch (error) {
            console.error('Error fetching tickets:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (id) => {
        const config = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('tok')}`
            }
        };

        try {
            await axios.delete(`${url}/tickets/${activeTab === 'vip' ? 'vip/' : ''}${id}`, config);
            // Refresh the tickets after deletion
            fetchTickets();
        } catch (error) {
            console.error('Error deleting ticket:', error);
        }
    };

    const {  hasNextPage, hasPreviousPage, page } = otherData;

    useEffect(() => {
        fetchTickets(1);
    }, [activeTab, searchQuery]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                <div className={classes.tabs}>
                    <button onClick={() => handleTabChange('regular')} className={activeTab === 'regular' ? classes.active : ''}>Regular</button>
                    <button onClick={() => handleTabChange('vip')} className={activeTab === 'vip' ? classes.active : ''}>VIP</button>
                </div>

                <div className={classes.inputContainer}>
                    <input
                        type="text"
                        placeholder="Search by code or owner..."
                        value={searchQuery}
                        onChange={handleSearch}
                        className={classes.input}
                    />
                </div>

                {isLoading ? (
                    <div className={classes.loaderContainer}>
                        <Skeleton width={'100%'} height={'100%'} />
                    </div>
                ) : (
                    <>
                        <table className={classes.table}>
                            <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>Code</th>
                                    <th>Game Owner</th>
                                    <th>Date Updated</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tickets.map((ticket, index) => (
                                    <tr key={ticket._id}>
                                        <td>{index + 1}</td>
                                        <td>{ticket.code}</td>
                                        <td>{ticket.gameOwner}</td>
                                        <td>{new Date(ticket.updatedAt).toLocaleDateString()}</td>
                                        <td>
                                            <button 
                                                className={classes.buttonContainer}
                                                onClick={() => handleDelete(ticket._id)}
                                            >
                                                <MdDeleteForever className={classes.deleteBtn} />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className={classes.pagination}>
                            <button onClick={() => fetchTickets(parseInt(page) - 1)} disabled={!hasPreviousPage}>Previous</button>
                            <span>Page {page} </span>
                            <button onClick={() => fetchTickets(parseInt(page) + 1)} disabled={!hasNextPage}>Next</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
