import React, { useState } from 'react';
import axios from 'axios';
import classes from './login.module.css';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { show } from '../../constant/toastOptions';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Loader } from '../../components/loader/loader';
import { useNavigate, useSearchParams } from 'react-router-dom';

const url = process.env.REACT_APP_BACKEND_URL;

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const handleSignIn = async (e) => {
        e.preventDefault();

        const redirect = searchParams.get("redirect")


        if (!email || !password) {
            show('Provide email & password!', 'error');
            return;
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        setIsLoading(true);
        try {
            const res = await axios.post(`${url}/auth/login`, { email, password }, config);
            localStorage.setItem('tok', res.data?.token);

            show('Sign In Successful!', 'success');

            if (redirect) {
                navigate(redirect)
            } else {
                navigate('/');
            }


        } catch (error) {
            const errorMessage = error?.response?.data?.msg || 'Something went wrong';
            show(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleForgotPassword = async () => {
        if (!email) {
            show('Please enter your email address!', 'error');
            return;
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        setIsLoading(true);
        try {
            await axios.patch(`${url}/auth/sendRefreshToken`, JSON.stringify({ email }), config);
            show('Password reset link sent to your email!', 'success');
        } catch (error) {
            const errorMessage = error?.response?.data?.msg || 'Something went wrong';
            show(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={classes.container}>
            <form onSubmit={handleSignIn} className={classes.subContainer}>
                <p className={classes.title}>Sign in to your account</p>
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={classes.input}
                />
                <div className={classes.passwordContainer}>
                    <input
                        type={isPasswordVisible ? 'text' : 'password'}
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={classes.passwordInput}
                    />
                    <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className={classes.eyeIcon}
                    >
                        {isPasswordVisible ? <FiEyeOff /> : <FiEye />}
                    </button>
                </div>
                <button type="submit" className={classes.button} disabled={isLoading}>
                    {isLoading ? <Loader /> : 'Sign In'}
                </button>
                <p className={classes.forgotPassword} onClick={handleForgotPassword}>Forgot Password?</p>
            </form>
            <ToastContainer />
        </div>
    );
}
