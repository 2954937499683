/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import classes from './convert.module.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { convertRoute, loginPath, subscribePath } from '../../constant/routes';
import { show } from '../../constant/toastOptions';
import axios from 'axios';
import { bookieOptions, bookiesDestination, directPlay } from '../../constant/bookies';
import { FiCopy } from 'react-icons/fi';
import { formatDateHeader } from '../../helpers/formatDateHeader';
import { format } from 'date-fns';
import { timeFormatter } from '../../helpers/timeFormatter';
import styles from '../index/index.module.css'
import { IoCloseSharp } from 'react-icons/io5';

const url = process.env.REACT_APP_BACKEND_URL;

export default function Convert({ standAlonePage }) {
    const [isLoading, setIsLoading] = useState(false);
    const [convertedCode, setConvertedCode] = useState(null);
    const [error, setError] = useState(null);
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const code = searchParams.get('code');
    const bookie = searchParams.get('bookie');


    const [value, setValue] = useState({
        booking_code: code || '',
        from: bookie || '',
        to: '',
    });



    useEffect(() => {
        if (standAlonePage && !localStorage.getItem('tok')) {
            navigate(`${loginPath}?redirect=${convertRoute}`);
        }
    }, [standAlonePage]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValue((prev) => ({ ...prev, [name]: value }));
    };

    const handleConvert = async () => {
        if (!value.booking_code || !value.from || !value.to) {
            show('Please enter all required fields.', 'error');
            return;
        }

        setIsLoading(true);
        setError(null);

        const token = localStorage.getItem('tok');

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const payload = {
                booking_code: value.booking_code,
                from: value.from,
                to: value.to,
            };

            const res = await axios.post(`${url}/convert`, JSON.stringify(payload), config);
            setConvertedCode(res?.data?.convertedCode);


            // const gamesArray = Array.from({ length: 20 }, (_, index) => ({
            //     game: `Team ${index + 1} - Team ${index + 2}`,
            //     odds: (Math.random() * (5 - 1) + 1).toFixed(2), // Random odds between 1 and 5
            //     start_date: new Date(Date.now() + index * 24 * 60 * 60 * 1000), // Each game starts a day after the previous
            //     prediction: `Prediction ${index + 1}`
            // }));

            // setConvertedCode({
            //     code: '3D09D3D',
            //     platform: 'sporty bet',
            //     countOfGames: 200,
            //     totalOdds: 30,
            //     games: gamesArray
            // })
            show('Conversion Successful', 'success');
        } catch (error) {
            const msg = error?.response?.data?.msg || 'Something went wrong';
            setError(msg);
            show(msg, 'error');

            if (msg.startsWith('VIP subscription') || msg.startsWith('Please subscribe')) {
                navigate(`${subscribePath}?redirect=${convertRoute}`);
            }

            if (msg.startsWith('Authentication')) {
                navigate(`${loginPath}?redirect=${convertRoute}`);
            }
        } finally {
            setIsLoading(false);
        }
    };


    const handleCopy = (code) => {
        navigator.clipboard.writeText(code).then(() => {
            show('Code copied to clipboard!', 'success');
        }).catch(err => {
            console.error('Failed to copy code: ', err);
        });
    };

    const placeBet = (bookie, code) => {
        const link = bookiesDestination(bookie, code)
        window.open(link, '_blank');
    }


    const handleShare = (code, platform) => {
        if (navigator.share) {
            navigator
                .share({
                    title: `I just converted this ${platform} - ${code} with BestCodes, you can visit the website to get quick and and sure odds`,
                    text: "https://www.bestcodes.vip/",
                })
                .then(() => {
                    show('Code shared!', 'success');
                })
                .catch((error) => {
                    show('Error sharing code!', 'error');
                });
        } else {
            alert("Sharing is not supported on this browser.");
        }
    };

    const convert = (code, bookie) => {
        const bookieValue = bookieOptions.find(item => item.bestCodeName === bookie);
        navigate(`${convertRoute}?code=${code}&bookie=${bookieValue.bookie}`)
    }

    return (
        <div className={classes.container}>
            <div className={`${classes.convertContainer} ${standAlonePage ? classes.standAlonePageConvertContainer : ''}`}>
                <div className={classes.inputContainer}>
                    <input
                        name="booking_code"
                        value={value.booking_code}
                        onChange={handleChange}
                        placeholder="Enter booking code here"
                        className={classes.input}
                    />
                </div>

                <div className={classes.smallInputContainersGrouped}>
                    <div className={classes.smallInputContainer}>
                        <select
                            name="from"
                            value={value.from}
                            onChange={handleChange}
                            className={classes.input}
                        >

                            <option disabled value={bookie}>{bookie || 'Convert from'}</option>
                            {
                                bookieOptions?.map((item, index) => (
                                    <option key={index} value={item.bookie}>
                                        {item.bookie}
                                    </option>
                                ))
                            }
                        </select>
                    </div>

                    <div className={classes.smallInputContainer}>
                        <select
                            name="to"
                            value={value.to}
                            onChange={handleChange}
                            className={classes.input}
                        >
                            <option disabled value={''}>Convert to</option>
                            {
                                bookieOptions?.map((item, index) => (
                                    <option key={index} value={item.bookie}>
                                        {item.bookie}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>

            <button
                onClick={handleConvert}
                className={`${classes.button} ${standAlonePage ? classes.standAlonePageButton : ''}`}
                disabled={isLoading}
            >
                {isLoading ? 'Converting...' : 'Convert'}
            </button>

            {convertedCode && (
                <div className={classes.resultContainer}>

                    <IoCloseSharp onClick={() => setConvertedCode(null)} className={classes.closeIcon} />
                    <div className={classes.resultSubContainer}>
                        <p className={classes.resultLabel}>Converted Code</p>
                        <div className={styles.ticketContainer}>
                            <div className={styles.topContainer}>
                                <div className={styles.copyContainer} onClick={() => handleCopy(convertedCode.code)}>
                                    <p className={styles.smallText}>{convertedCode.platform}</p>
                                    <p className={styles.largeBoldText}>{convertedCode.code}</p>
                                    <p className={styles.redText}>Copy code <FiCopy /></p>
                                </div>
                                <div className={styles.groupedTotalOddsGameOwner}>
                                    <p className={styles.largeOdds}>{convertedCode.totalOdds}</p>
                                    <p className={styles.smallText}>{convertedCode.countOfGames} game(s) by {convertedCode.gameOwner}</p>
                                </div>
                            </div>

                            <div className={styles.groupedGamesContainer}>
                                {convertedCode?.games?.map((game, gameIndex) => (
                                    <div key={gameIndex} className={styles.gameContainer}>
                                        <div>
                                            <p className={styles.prediction}>{game.prediction}</p>
                                            <p className={styles.game}>{game.game}</p>
                                            <p className={styles.timeStamp}>
                                                {formatDateHeader(format(new Date(game.start_date), 'yyyy-MM-dd'))} at {timeFormatter(new Date(game.start_date).getTime())}
                                            </p>
                                        </div>
                                        <p className={styles.gameOdd}>{game.odds}</p>
                                    </div>
                                ))}
                            </div>
                            {directPlay.includes(convertedCode.platform) && (
                                <button onClick={() => placeBet(convertedCode.platform, convertedCode.code)} className={styles.placeBetBtn}>
                                    Place Bet
                                </button>
                            )}

                            <div className={styles.shareBtnContainer}>
                                <button className={styles.shareBtn} onClick={() => handleShare(convertedCode.code, convertedCode.platform)}>Share Code</button>
                                <button className={styles.shareBtn} onClick={() => convert(convertedCode.code, convertedCode.platform)}>Convert Code</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {error && <p className={classes.error}>{error}</p>}
        </div>
    );
}
