import React, { useState, useContext } from 'react';
import axios from 'axios';
import classes from './accountInformation.module.css';
import { show } from '../../constant/toastOptions';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Loader } from '../../components/loader/loader';
import UserContext from '../../context/User/userContext';
import { useNavigate } from 'react-router-dom';
import { profilePath } from '../../constant/routes';

const url = process.env.REACT_APP_BACKEND_URL;

export default function UpdateAccountInformation() {
    const { user, setUser } = useContext(UserContext);
    const [firstName, setFirstName] = useState(user?.first_name || '');
    const [lastName, setLastName] = useState(user?.last_name || '');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()

    const handleUpdate = async (e) => {
        e.preventDefault();

        if (!firstName || !lastName) {
            show('Provide both first name and last name!', 'error');
            return;
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('tok')}`,
            },
        };

        setIsLoading(true);
        try {
            const res = await axios.patch(`${url}/users`, { first_name: firstName, last_name: lastName }, config);
            setUser(res.data.user);
            show('Account information updated successfully!', 'success');
            navigate(profilePath)
        } catch (error) {
            const errorMessage = error?.response?.data?.msg || 'Something went wrong';
            show(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={classes.container}>
            <form onSubmit={handleUpdate} className={classes.subContainer}>
                <p className={classes.title}>Update Account Information</p>
                <input
                    type="text"
                    placeholder="Enter your first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className={classes.input}
                />
                <input
                    type="text"
                    placeholder="Enter your last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className={classes.input}
                />
                <button type="submit" className={classes.button} disabled={isLoading}>
                    {isLoading ? <Loader /> : 'Update'}
                </button>
            </form>
            <ToastContainer />
        </div>
    );
}
